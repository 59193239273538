import React, { Component } from "react";

type Props = {
	hidePressKitButton?: boolean;
}

class PressSubNav extends Component<Props> {

	render() {
		const { hidePressKitButton } = this.props;
		return (
			<React.Fragment>
				<div className={"flex items-center text-sm md:text-md"}>
					<span>
						Press contact:
					</span>
					<a
						href={"mailto:press@jeti.app"}
						className={"font-semibold hover:text-secondary"}
					>
						&nbsp;press@jeti.app
					</a>
				</div>
				{!hidePressKitButton && (
					<a
						href={"/press-kit"}
					>
						<span className={"font-medium text-primary hover:text-secondary text-sm md:text-md"}>
							Press Kit
						</span>
					</a>
				)}
			</React.Fragment>
		);
	}

}

export default PressSubNav;
