import React, { Component } from "react";
import SEO from "../components/SEO";
import SubLayout from "../components/layouts/SubLayout";
import PressSubNav from "../components/PressSubNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import logoWhite from "../images/logo-text-white.png";
import logoBlue from "../images/logo-text-blue.png";
import iconCircle from "../images/icon-circle.png";
import appIcon from "../images/logo-icon.png";

class PressKit extends Component {

	render() {
		return (
			<SubLayout
				title={"Press Kit"}
				subNav={(<PressSubNav hidePressKitButton={true}/>)}
			>
				<SEO
					title={"Jeti | Press Kit"}
					description={"Promotional material, brand guidelines, and assets for members of the media."}
				/>
				<div className={""}>
					<h2 className={"text-4xl text-center my-20"}>
						Jeti <span className={"font-bold"}>Branding</span>
					</h2>
					<div className={"flex items-center justify-center flex-col md:flex-row"}>
						<div className={"md:w-1/4 text-center px-3"}>
							<div className={"rounded-lg shadow-lg bg-black p-5"}>
								<img
									src={logoWhite}
								/>
							</div>
							<a href={logoWhite} download>
								<div className={"font-medium text-primary mt-3"}>
									Download
									<FontAwesomeIcon
										icon={faDownload}
										size={"sm"}
										className={"ml-2"}
									/>
								</div>
							</a>
						</div>
						<div className={"md:w-1/4 text-center px-3 mt-5 md:mt-0"}>
							<div className={"rounded-lg shadow-lg bg-gray-100 p-5"}>
								<img
									src={logoBlue}
								/>
							</div>
							<a href={logoBlue} download>
								<div className={"font-medium text-primary mt-3"}>
									Download
									<FontAwesomeIcon
										icon={faDownload}
										size={"sm"}
										className={"ml-2"}
									/>
								</div>
							</a>
						</div>
					</div>
					<div className={"flex items-center justify-center mt-5 md:mt-10 flex-col md:flex-row"}>
						<div className={"w-2/3 md:w-1/6 text-center px-3"}>
							<div className={"rounded-lg shadow-lg bg-black p-5"}>
								<img
									src={iconCircle}
								/>
							</div>
							<a href={iconCircle} download>
								<div className={"font-medium text-primary mt-3"}>
									Download
									<FontAwesomeIcon
										icon={faDownload}
										size={"sm"}
										className={"ml-2"}
									/>
								</div>
							</a>
						</div>
						<div className={"w-2/3 md:w-1/6 text-center px-3 mt-5 md:mt-0"}>
							<div className={"rounded-lg shadow-lg bg-black p-5"}>
								<img
									src={appIcon}
								/>
							</div>
							<a href={appIcon} download>
								<div className={"font-medium text-primary mt-3"}>
									Download
									<FontAwesomeIcon
										icon={faDownload}
										size={"sm"}
										className={"ml-2"}
									/>
								</div>
							</a>
						</div>
					</div>
					<h2 className={"text-4xl text-center my-20"}>
						Jeti <span className={"font-bold"}>Colors</span>
					</h2>
					<div className={"flex items-center justify-center flex-col md:flex-row"}>
						<div className={"text-center px-3"}>
							<div className={"rounded-lg shadow-lg bg-primary p-10 text-white font-bold text-xl"}>
								#54ADEE
							</div>
							<div className={"mt-3 leading-tight text-gray-500 text-sm"}>
								<p>
									Jeti Blue
								</p>
								<p>
									rgb(84, 173, 238)
								</p>
							</div>
						</div>
						<div className={"text-center px-3 mt-5 md:mt-0"}>
							<div className={"rounded-lg shadow-lg bg-secondary p-10 text-white font-bold text-xl"}>
								#F25C7A
							</div>
							<div className={"mt-3 leading-tight text-gray-500 text-sm"}>
								<p>
									Jeti Pink
								</p>
								<p>
									rgb(242, 92, 122)
								</p>
							</div>
						</div>
					</div>
				</div>
			</SubLayout>
		);
	}

}

export default PressKit;
