import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "./Layout";

type Props = {
	children: React.ReactNode;
	subNav: React.ReactNode;
	title?: string;
};

class SubLayout extends Component<Props> {

	render() {
		const { children, subNav, title } = this.props;
		return (
			<Layout
				className={"bg-black shadow-lg"}
				useWhiteLogo={true}
			>
				<div className={"py-3 bg-gray-200"}>
					<div className={"container mx-auto flex items-center justify-between flex-col md:flex-row"}>
						{subNav}
					</div>
				</div>
				<div className={"container mx-auto mb-20 px-5 xl:px-0"}>
					{title && (
						<h1 className={"text-5xl md:text-7xl font-black my-3 md:my-8 border-b-2 leading-none py-5 md:py-10"}>
							{title}
						</h1>
					)}
					{children}
				</div>
			</Layout>
		);
	}

}

export default SubLayout;
